import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { _CreateCourse } from "@Services/Courses";
import { Course } from "types/Courses";
import PopupsLayout from "@Components/PopupsLayout";
import { useForm, Controller } from "react-hook-form";
import { FormHelperText, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TeachersDatasContext } from "context/Teachers.context";
import { removeEmptyProperties } from "utils/Forms";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import { userInfo } from "context/UserInfo.context";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { AddCourseMessage } from "@Components/PopupsLayout/PopupsLayout.styled";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const AddCoursePopup = ({ isOpen, closePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const { userData } = userInfo();
  const { subjectId, refetchSubjectCourses, refetchSubjectStatistics, setEditSubjectPrice } =
    useContext(TeachersDatasContext);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<Course>();

  const isFreeCourse = Boolean(watch("is_free") === "true");

  useEffect(() => {
    if (!isOpen) {
      setSubmitValidation("");
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isFreeCourse) {
      setValue("discount", 0);
      setValue("price", 0);
    }
  }, [watch("is_free")]);

  const submitForm = (data: Course) => {
    setIsLoading(true);
    const filteredData = removeEmptyProperties(data);
    _CreateCourse({
      ...filteredData,
      is_active: data?.is_active === "false" ? false : true,
      is_free: data?.is_free === "false" ? false : true,
      subject_id: subjectId,
    })
      .then(() => {
        closePopup(false);
        if (
          watch("price") !== 0 &&
          watch("is_active")?.toString() === "true" &&
          watch("is_free")?.toString() === "false"
        ) {
          setEditSubjectPrice(true);
        } else {
          setEditSubjectPrice(false);
        }
        reset();
        refetchSubjectCourses();
        refetchSubjectStatistics();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_course")}
          buttonTitle={tCommon("add_course")}
          buttonState={!isDirty}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            {isRegularInstructor && <AddCourseMessage>{tCommon("add_course_message")}</AddCourseMessage>}
            <Input
              id="filled-basic"
              variant="filled"
              label={tCommon("link")}
              disabled={isRegularInstructor}
              {...register("link")}
            />
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("vimeo_folder_id")}
                disabled={isRegularInstructor}
                {...register("vimeo_folder_id", {
                  valueAsNumber: true,
                })}
              />
              <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                <Controller
                  name="vimeo_account"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      {...register("vimeo_account")}
                    >
                      <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                      <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <FormControl variant="filled" fullWidth error={Boolean(errors.is_free)} disabled={isRegularInstructor}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("pricing")}</InputLabel>
              <Controller
                name="is_free"
                control={control}
                defaultValue=""
                rules={{
                  required: tCommon("required_message"),
                }}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="false">{tCommon("paid")}</MenuItem>
                    <MenuItem value="true">{tCommon("free")}</MenuItem>
                  </Select>
                )}
              />
              {errors.is_free && <FormHelperText>{errors.is_free?.message}</FormHelperText>}
            </FormControl>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("course_cost")}
                {...register("price", {
                  required: tCommon("required_message"),
                  valueAsNumber: true,
                })}
                InputLabelProps={{
                  shrink: isFreeCourse || undefined,
                }}
                disabled={isFreeCourse || isRegularInstructor}
                error={Boolean(errors.price)}
                helperText={errors.price && !isFreeCourse && errors.price?.message}
              />
              <Input
                id="filled-basic"
                variant="filled"
                className="noArrows no_padding"
                type="number"
                label={tCommon("discount")}
                InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment> }}
                {...register("discount", {
                  min: {
                    value: 0,
                    message: tCommon("discount_less_validation"),
                  },
                  max: {
                    value: 100,
                    message: tCommon("discount_higher_validation"),
                  },
                  required: tCommon("required_message"),
                  valueAsNumber: true,
                })}
                disabled={isFreeCourse || isRegularInstructor}
                error={Boolean(errors.discount)}
                helperText={errors.discount && !isFreeCourse && errors.discount?.message}
              />
            </InputsWrapper>
            <FormControl variant="filled" fullWidth error={Boolean(errors.is_active)} disabled={isRegularInstructor}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
              <Controller
                name="is_active"
                control={control}
                defaultValue=""
                rules={{
                  required: tCommon("required_message"),
                }}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value="true">{tCommon("active")}</MenuItem>
                    <MenuItem value="false">{tCommon("inactive")}</MenuItem>
                  </Select>
                )}
              />
              {errors.is_active && <FormHelperText>{errors.is_active?.message}</FormHelperText>}
            </FormControl>
            <MultibleLanguagesInputs
              isOpen={isOpen}
              errors={errors}
              register={register}
              isDisabled={isRegularInstructor}
            />
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddCoursePopup;

import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import PopupsLayout from "@Components/PopupsLayout";
import { Controller, useForm } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { _UpdateSection } from "@Services/Sections";
import { Section } from "types/Sections";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
}

const EditSectionPopup = ({ isOpen, closePopup, openDeletePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const [disablebutton, setDisablebutton] = useState(true);
  const { userData } = userInfo();
  const { sectionData, sectionId, refetchCourseDetails, teacherId, sectionDataRefetch } =
    useContext(TeachersDatasContext);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Section>();

  useEffect(() => {
    if (sectionData) {
      reset({
        vimeo_folder_id: sectionData?.vimeo_folder_id,
        vimeo_account: sectionData?.vimeo_folder_id ? sectionData?.vimeo_account : "",
        lang_name: {
          en: sectionData?.lang_name?.en ? sectionData?.lang_name?.en : "",
          tr: sectionData?.lang_name?.tr ? sectionData?.lang_name?.tr : "",
          ar: sectionData?.lang_name?.ar ? sectionData?.lang_name?.ar : "",
        },
      });
    }
    setSubmitValidation("");
    setDisablebutton(true);
  }, [sectionData, isOpen]);

  const submitForm = (data: Section) => {
    const { lang_name } = data;
    setIsLoading(true);
    _UpdateSection(isRegularInstructor ? { lang_name } : data, sectionId, teacherId)
      .then(() => {
        closePopup(false);
        refetchCourseDetails();
        sectionDataRefetch();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_section")}
          buttonTitle={tCommon("save_changes")}
          buttonState={Boolean(!isDirty && disablebutton)}
          closePopup={closePopup}
          showDeleteButton
          deleteButtonStatus={isRegularInstructor}
          deleteTitle={tCommon("delete_section")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                disabled={isRegularInstructor}
                className="noArrows"
                label={tCommon("vimeo_folder_id")}
                {...register("vimeo_folder_id", {
                  valueAsNumber: true,
                })}
              />
              <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                <Controller
                  name="vimeo_account"
                  control={control}
                  defaultValue={sectionData ? sectionData?.vimeo_account : ""}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      {...register("vimeo_account")}
                    >
                      <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                      <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <MultibleLanguagesInputs
              data={sectionData}
              isSection
              isOpen={isOpen}
              errors={errors}
              setValue={setValue}
              register={register}
              setDisablebutton={setDisablebutton}
            />
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditSectionPopup;
